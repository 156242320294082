
import { fetchPage, Page } from "../serviceClients/pageWebServices";
import { Component, Vue } from "vue-property-decorator";
import store from "@/store";
import { pushError } from "@/core";

@Component
export default class ProMarketingProgramComponent extends Vue {
  isLoading = false;

  page: Page = {} as Page;

  get pageText() {
    return this.page?.text;
  }

  async mounted() {
    store.commit.module.setActiveModuleName(this.$t("PRO Marketing Program"));

    await this.loadPage();
  }

  async loadPage() {
    this.isLoading = true;
    try {
      this.page = (await fetchPage("ProMarketing")).data;
    } catch (error) {
      pushError(error.response?.data?.error || error);
    } finally {
      this.isLoading = false;
    }
  }

  get breadcrumbsList() {
    return  [
      {
        text: this.$t('Home'),
        href: '/'
      },
      {
        text: this.$t('PRO Marketing Program'),
        href: '#'
      }
    ]
  }
}
